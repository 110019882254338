import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["source", "label", "button"];


  async copy(e) {
    e.preventDefault();

    try {
      await navigator.clipboard.writeText(this.sourceTarget.textContent);

      const previousLabel = this.labelTarget.innerHTML;
      this.label = "Copied!";

      _.delay(
        (label, text) => {
          label.innerHTML = text;
        },
        2000,
        this.labelTarget,
        previousLabel
      );
    } catch (err) {
      console.error('Failed to copy:', err);
      this.label = "Copy failed";
    } finally {
    }
  }

  get label() {
    return this.labelTarget;
  }

  set label(text) {
    this.labelTarget.innerHTML = text;
  }
}
